<template>
    <img class="mx-auto h-12 w-auto" :src="logoUrl" alt="SNS">
</template>
<script setup>
import { ref, computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';



const logoUrl = computed(() => `${import.meta.env.BASE_URL}storage/images/logo.jpg`);

</script>